import { http } from '../utils/http';

const path = process.env.VUE_APP_PATH + '/driver-center';

// 获取咨询列表
export const getConsultationList = async data => {
  return await http['post'](
    `${path}/driver/mine/customerCenter/businessConsultation/searchBusinessConsultationList`,
    data,
    false
  );
};
// 插入咨询记录
export const addBusinessConsultation = async data => {
  return await http['post'](
    `${path}/driver/mine/customerCenter/businessConsultation/addBusinessConsultation`,
    data,
    false
  );
};
// 根据ID查询投诉详情
export const getBusinessConsultationById = async data => {
  return await http['post'](
    `${path}/driver/mine/customerCenter/businessConsultation/getBusinessConsultationById`,
    data,
    false
  );
};

export const getServicePhone = async data => {
  return await http['post'](`${path}/driver/mine/customerCenter/getServicePhone`, data, false);
};

// 获取投诉列表
export const getComplainList = async data => {
  return await http['post'](
    `${path}/driver/mine/customerCenter/reportComplaints/searchReportComplaintsList`,
    data,
    false
  );
};
// 插入投诉记录
export const addReportComplaints = async data => {
  return await http['post'](`${path}/driver/mine/customerCenter/reportComplaints/addReportComplaints`, data, false);
};
// 根据ID查询投诉详情
export const getReportComplaintsById = async data => {
  return await http['post'](`${path}/driver/mine/customerCenter/reportComplaints/getReportComplaintsById`, data, false);
};
