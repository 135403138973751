<template>
  <Page :hasBg="false" class="consulting-content">
    <!-- 导航栏 -->
    <NavBar :title="typeText"> </NavBar>

    <div class="content pa-16">
      <div class="pb-12 text-16 font-500"><span class="text-danger">*</span>具体内容</div>
      <!-- 文本框 -->
      <Field
        v-model.trim="text"
        rows="3"
        autosize
        type="textarea"
        maxlength="100"
        :placeholder="placeholderText"
        show-word-limit
        :border="false"
      />

      <div class="mt-24">
        <div class="pb-12 text-16 font-500">关联运单号</div>
        <Field v-model.trim="transportBillNumber" placeholder="请输入运单号" class="py-10" />
      </div>

      <Button class="mt-24" style="width: 100%" :disabled="!text" size="large" @click="handleSubmit" type="primary">
        提交
      </Button>
    </div>
  </Page>
</template>

<script>
import { Button, Toast, Field } from 'vant';
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, ref } from '@vue/composition-api';
import { useRoute } from '@/utils/compsitionHack';
import useGetDifferenceInfo from './hooks/useGetDifferenceInfo';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar,
    Button,
    Field
  },
  setup(_, ctx) {
    const { query } = useRoute();

    // 文本内容
    const text = ref('');

    const transportBillNumber = ref('');

    // query.type - 0: 业务咨询 1: 举报投诉
    const { typeText, apiName } = useGetDifferenceInfo(+query.type);

    const handleSubmit = async () => {
      try {
        const res = await apiName({ submitterContent: text.value, transportBillNumber: transportBillNumber.value });
        if (res) {
          Toast('提交成功！');
          // 如果是在客户端环境，调用客户端返回方法
          // ctx.root.$routerBack.call(ctx.root._router);
          ctx.root.$router.back();
        }
      } catch (err) {
        Toast.fail(err);
      }
    };

    return {
      typeText,
      text,
      handleSubmit,
      transportBillNumber,
      placeholderText:
        +query.type === 0 ? '点击输入咨询内容（最长支持100个字符）' : '点击输入投诉内容（最长支持100个字符）'
    };
  }
});
</script>
<style lang="less" scoped>
.consulting-content {
  .text {
    width: 100%;
    border: 0.01rem solid rgba(188, 188, 188, 0.5);
    min-height: 1.86rem;
    resize: none;
    border-radius: 0.02rem;
    &::placeholder {
      color: #bcbcbc;
    }
  }
  .content {
    position: relative;
    .count {
      position: absolute;
      right: 0.28rem;
      top: 1.78rem;
      color: #999;
    }
  }
}
</style>
