/**
 * @param {Number} type - 0: 业务咨询 1: 举报投诉
 */

import { addBusinessConsultation, addReportComplaints } from '@api/customer';

export default type => {
  let differenceInfo;
  if (type) {
    differenceInfo = {
      typeText: '举报投诉',
      apiName: addReportComplaints
    };
  } else {
    differenceInfo = {
      typeText: '业务咨询',
      apiName: addBusinessConsultation
    };
  }
  return differenceInfo;
};
